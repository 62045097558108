import React, { useEffect, useRef } from "react"
import classnames from "classnames/bind"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import LocomotiveScroll from "locomotive-scroll"

// Vendors
import "../../node_modules/locomotive-scroll/dist/locomotive-scroll.min.css"

function layoutWrapperBgChange() {
  gsap.timeline({
    ease: "none",
    scrollTrigger: {
      id: "layoutWrapperBgChange",
      scroller: ".scrollContainer",
      trigger: "#HomepageExpr",
      start: "top center",
      end: "bottom top",
      markers: true,
      // pinReparent: true,
      scrub: true,
      toggleClass: {
        targets: "#layoutWrapper",
        className: "bg-light-main",
      },
    },
  })
}

function marqueeColorChange() {
  const MarqueeTitle = document.querySelectorAll(".MarqueeTitleText")
  const MarqueeTitleArray = Array.apply(null, MarqueeTitle)
  const MarqueeTitleTextFirst = MarqueeTitleArray.slice(0, 4)

  gsap.timeline({
    ease: "none",
    scrollTrigger: {
      id: "marqueeColorChange",
      trigger: "#HomepageExpr",
      scroller: ".scrollContainer",
      start: "top center",
      end: "bottom top",
      markers: true,
      toggleClass: {
        targets: MarqueeTitleTextFirst,
        className: "txt-hasShadow-white",
      },
    },
  })
}

const ScrollWrapper = props => {
  const smoothRef = useRef(null)

  useEffect(() => {
    const locoScroll = new LocomotiveScroll({
      el: smoothRef.current,
      smooth: true,
      smoothMobile: false,
      getDirection: true,
      touchMultiplier: 2.5,
      lerp: 0.15,
    })

    // Exposing to the global scope for ease of use.
    window.scroll = locoScroll

    // each time Locomotive Scroll updates, tell ScrollTrigger to update too (sync positioning)
    locoScroll.on("scroll", ScrollTrigger.update)

    // tell ScrollTrigger to use these proxy methods for the "#smoothScroll" element since Locomotive Scroll is hijacking things
    ScrollTrigger.scrollerProxy(smoothRef.current, {
      scrollTop(value) {
        return arguments.length
          ? locoScroll.scrollTo(value, 0, 0)
          : locoScroll.scroll.instance.scroll.y
      }, // we don't have to define a scrollLeft because we're only scrolling vertically.
      getBoundingClientRect() {
        return {
          top: 0,
          left: 0,
          width: window.innerWidth,
          height: window.innerHeight,
        }
      },
      // LocomotiveScroll handles things completely differently on mobile devices - it doesn't even transform the container at all! So to get the correct behavior and avoid jitters, we should pin things with position: fixed on mobile. We sense it by checking to see if there's a transform applied to the container (the LocomotiveScroll-controlled element).
      pinType: smoothRef.current.style.transform ? "transform" : "fixed",
    })

    // To all of them beforehand, set scroller to "#smoothScroll" element
    ScrollTrigger.defaults({ scroller: smoothRef.current, markers: true })

    // layoutWrapperBgChange()
    // marqueeColorChange()

    // setInterval(() => locoScroll.update(), 1000)

    // each time the window updates, we should refresh ScrollTrigger and then update LocomotiveScroll.
    ScrollTrigger.addEventListener("refresh", () => locoScroll.update())

    // after everything is set up, refresh() ScrollTrigger and update LocomotiveScroll because padding may have been added for pinning, etc.
    ScrollTrigger.refresh()

    return () => {
      if (locoScroll) {
        locoScroll.destroy()
        ScrollTrigger.removeEventListener("refresh", () => locoScroll.update())
      }
      if (ScrollTrigger.getById("layoutWrapperBgChange")) {
        ScrollTrigger.getById("layoutWrapperBgChange").kill()
      }
      if (ScrollTrigger.getById("marqueeColorChange")) {
        ScrollTrigger.getById("marqueeColorChange").kill()
      }
    }
  }, [])

  return pug`
    div.scrollContainer.overflow-y-scroll(ref=smoothRef)
      ${props.children}
  `
}
export default ScrollWrapper
